<template>
  <ResourceView>
    <VHeader>
      <template #avatar>
        <VImage
          :src="getAvatarURL(data?.learner?.avatar?.view_path)"
          :width="12"
          :height="12"
          class="items-center justify-center"
        />
      </template>

      <template #title>
        {{ name }}

        <div class="label text-sm font-light">
          {{ username }}
        </div>
      </template>

      <template #status>
        <VChip
          v-if="data.status"
          :text="$t(`app.${data.status}`)"
          :class="getStatusColor(data.status)"
        />
      </template>

      <template #tabs>
        <div class="flex justify-between">
          <VTabs
            :current-tab="currentTab"
            :tabs="tabs.slice(0, -1)"
            @click="onClickRedirect"
          />

          <VTabs
            :current-tab="currentTab"
            :tabs="tabs.slice(-1)"
            @click="onClickRedirect"
          />
        </div>
      </template>
    </VHeader>

    <router-view
      :id="id"
      :resource="data"
      :is-loading="isLoading"
      @click:cancel="onClickCancel"
      @click:save="onClickSave"
      @refresh="getData"
    />
  </ResourceView>
</template>

<script>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
// Composables
import useDisplay from "@/composables/useDisplay";
import useRegistration from "@/composables/useRegistration";
import useUpdate from "@/composables/useUpdate";
import useColor from "@/composables/useColor";
import useOptions from "@/composables/useOptions";
import useTabs from "@/composables/useTabs";
import useTeachingLogs from "@/composables/useTeachingLogs";
// Components
import ResourceView from "./ResourceView";
import VHeader from "@/components/VHeader";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";
import VTabs from "@/components/VTabs";

export default {
  components: {
    ResourceView,
    VHeader,
    VChip,
    VTabs,
    VImage
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const route = useRoute();

    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { buildTab } = useTabs();
    const { isAuthorized } = useTeachingLogs();
    const { getAvatarURL } = useDisplay();
    const { getStatusColor } = useColor();
    const { endpoint, route: baseRoute } = useRegistration();
    const { data, onClickCancel, onClickSave, isLoading, getData } = useUpdate({
      endpoint,
      route,
      id: props.id,
      relations: [
        "learner",
        "training",
        "collection",
        "lessons",
        "attestations",
        "evaluations"
      ]
    });

    // Computed
    const status = computed(() => data.value?.status);
    // eslint-disable-next-line
    const isRegistrationRequested = computed(() => status.value === ALL_OPTIONS.REGISTRATION_REQUESTED.value);
    const tabs = computed(() => {
      return [
        buildTab({ name: "details" }),
        buildTab({
          name: "progress",
          disabled: isRegistrationRequested.value
        }),
        buildTab({
          name: "logs",
          disabled: !isAuthorized.value
        })
      ];
    });

    const currentTab = computed(() => {
      const availableTabs = tabs.value.reduce(
        (acc, tab) => ({
          ...acc,
          [`${baseRoute}-${tab.name}`]: tab.name
        }),
        {}
      );

      return availableTabs[route.name] ?? "";
    });

    const name = computed(() => {
      const { firstname, lastname } = data.value?.learner ?? {};

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });

    const username = computed(() => {
      const username = data.value?.learner?.username;

      return username ? `@${username}` : "";
    });

    // Methods
    const onClickRedirect = tab => {
      router.push({
        name: `${baseRoute}-${tab}`,
        params: {
          id: props.id
        }
      });
    };

    return {
      name,
      username,
      onClickRedirect,
      tabs,
      currentTab,
      // useDisplay
      getAvatarURL,
      // useColor
      getStatusColor,
      // useUpdate
      data,
      onClickCancel,
      onClickSave,
      isLoading,
      getData
    };
  }
};
</script>
